import React from "react";
import "../css/map.css";

const Map = () => {
  return (
    <div className="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2710.1695845268123!2d8.361822811303355!3d47.21326400758053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4790038ef8cd7299%3A0xd3a751417e90d458!2sIndustriestrasse%2019%2C%205644%20Auw!5e0!3m2!1sde!2sch!4v1628422666668!5m2!1sde!2sch" width="600" height="450" frameBorder="0" bordercolor="white" flex-shrink="2"></iframe>
    </div>
  );
};

export default Map;


